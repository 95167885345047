<template>
  <div style="width: 100%;">
    <v-toolbar class="top-header">
      <v-app-bar-nav-icon @click="toggleDrawer" />

      <v-spacer />
      <!-- <v-btn-toggle v-model="toggle_exclusive">
        <v-btn v-if="isDebug" text color="primary" small @click="changeToLocal"
               :class="{ 'v-item--active v-list-item--active': currentServer.baseUrl === local }">
          <v-list-title>
            Local
          </v-list-title>
        </v-btn>
        <v-btn text color="primary" small @click="changeToStaging"
               :class="{ 'v-item--active v-list-item--active': currentServer.baseUrl === staging }">
          <v-list-title>
            Staging
          </v-list-title>
        </v-btn>
        <v-btn text color="primary" small @click="changeToLiveStaging"
               :class="{ 'v-item--active v-list-item--active': currentServer.baseUrl === livestaging }">
          <v-list-title>
            Live
            Staging
          </v-list-title>
        </v-btn>
        <v-btn text color="primary" small @click="changeToLive"
               :class="{ 'v-item--active v-list-item--active': currentServer.baseUrl === live }">
          <v-list-title>Live</v-list-title>
        </v-btn>
      </v-btn-toggle> -->
      <!-- <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-4" color="primary" small outlined v-bind="attrs" v-on="on" fab @click.prevent="dialog = true">
            <v-icon>mdi-paperclip</v-icon>
          </v-btn>
        </template>
<span>Upload network files CSV</span>
</v-tooltip> -->
    </v-toolbar>
    <v-divider />
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card class="pa-4">
          <div class="upload-container" @click="openFileInput" @dragover.prevent @dragenter.prevent @dragleave.prevent
               @drop="handleFileDrop">
            <v-icon size="64">
              mdi-file-upload
            </v-icon>
            <p class="upload-text">
              {{ fileMessage }}
            </p>
          </div>
          <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" accept=".csv">
        </v-card>

        <v-card-actions>
          <v-spacer />
          <v-btn color="error darken-1" text
                 @click="dialog = false; selectedFile = null; fileMessage = 'Drag & Drop your CSV file here'">
            Close
          </v-btn>
          <v-btn color="green darken-1" text @click="SendCsvHandler">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="2000">
      File Uploaded Successfully
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDebug: process.env.NODE_ENV === 'development',
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      isAdmin: localStorage.getItem('menus') === 'ALL' ? true : false,
      dialog: false,
      snackbar: false,
      selectedFile: null,
      fileMessage: 'Drag & Drop your CSV file here'
    }
  },
  methods: {
    toggleDrawer() {
      this.$emit('toggleDrawer'); // Emit an event to the parent component
    },
    /* changeToLocal() {
      this.$emit('changeToLocal'); // Emit an event to the parent component
    },
    changeToStaging() {
      this.$emit('changeToStaging'); // Emit an event to the parent component
    },
    changeToLiveStaging() {
      this.$emit('changeToLiveStaging'); // Emit an event to the parent component
    },
    changeToLive() {
      this.$emit('changeToLive'); // Emit an event to the parent component
    }, */



    // this area is for upload csv file 
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    handleFileDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    uploadFile(file) {
      // Handle the file upload logic here
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        // File is a CSV, you can proceed with the upload logic
        this.selectedFile = file;
        this.fileMessage = file.name;
        console.log('Uploading CSV file:', file.name);
        // Implement your upload logic here
      } else {
        // File is not a CSV
        alert('Please upload a CSV file');
      }
    },
    async SendCsvHandler() {
      // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
      const baseurl = process.env.VUE_APP_API_BASE_URL
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      try {
        const response = await fetch(baseurl + 'api/v2/network_files_mqs/test_upload', {
          method: 'POST',
          headers: myHeaders,
          body: formData,
          redirect: 'follow'
        });
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else if (response.status == '422') {
            throw new Error('Unprocessable Entity');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.text();
        console.log(data)
        this.fileMessage = 'Drag & Drop your CSV file here'
        this.dialog = false
        this.snackbar = true
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          alert(error.message)
          this.dialog = false
          this.fileMessage = 'Drag & Drop your CSV file here'
        }
      }
    }
  },
  props: {
    currentServer: Object,
  },
};
</script>
<style lang="scss">
.v-sheet {
  .v-toolbar__content {
    padding: 0px;
  }

  box-shadow: none !important;

  &.top-header {
    .v-toolbar__content {
      padding: 4px 16px;
    }
  }
}

.upload-container {
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.upload-text {
  margin-top: 10px;
}

input[type="file"] {
  display: none;
}
</style>
