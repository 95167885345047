<template>
  <v-app>
    <v-navigation-drawer app :value="drawer" @input="drawer = $event">
      <Sidebar />
    </v-navigation-drawer>

    <v-app-bar app>
      <Header :current-server="currentServer" @toggleDrawer="toggleDrawer" />
    </v-app-bar>

    <v-main class="main-body">
      <div class="pa-4">
        <router-view :main-url="mainUrl" :current-server="currentServer" />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Header from './Header.vue';
import Sidebar from './Sidebar.vue'; // Import the Header component
import '../style/main.scss'
import '../style/main-md.scss'
export default {
  components: {
    Header,
    Sidebar
  },
  data() {
    return {
      drawer: window.innerWidth <= 1264 ? false : true,
      mainUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  props: {
    currentServer: Object,
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer; // Toggle the drawer state
    },
  },
  watch: {
    '$vuetify.breakpoint.width'(newWidth) {
      this.drawer = newWidth > 1264;
    },
  },
};
</script>